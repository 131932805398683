<template>
  <div class="modal-card">
    <div class="modal-content">
      <div class="box">
        <div class="columns is-mobile">
          <div class="column">
            <p class="subtitle has-text-primary">
              Add a new user to your branch
            </p>
          </div>
          <div class="column is-narrow">
            <a class="has-text-danger" @click="close">Close</a>
          </div>
        </div>
        <div class="columns is-multiline is-centered">
          <div class="column is-12">
            <form class="form" @submit.prevent="submit">
              <div class="columns is-multiline">
                <div class="column">
                  <div class="field">
                    <label class="label">Name</label>
                    <input
                      v-model="user.name"
                      v-validate="'required'"
                      type="text"
                      name="name"
                      class="input is-medium"
                    />
                    <span
                      v-if="errors.first('name')"
                      class="help has-text-danger"
                      >{{ errors.first('name') }}</span
                    >
                  </div>
                  <div class="field">
                    <label class="label">Email</label>
                    <input
                      v-model="user.email"
                      v-validate="'required|email'"
                      type="text"
                      name="email"
                      class="input is-medium"
                    />
                    <span
                      v-if="errors.first('email')"
                      class="help has-text-danger"
                      >{{ errors.first('email') }}</span
                    >
                  </div>
                </div>
                <div class="column is-12">
                  <div class="buttons is-right">
                    <button class="button is-text" @click.prevent="close">
                      Cancel
                    </button>
                    <button
                      class="button is-rounded is-soft"
                      :class="{ 'is-loading': $wait.is('submit') }"
                      :disabled="$wait.is('submit')"
                    >
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as account from 'modules/account/services'
import { mapGetters } from 'vuex'
export default {
  name: 'AccountAdminAddUser',
  data: () => ({
    user: {
      branches: null,
      name: null,
      email: null,
      source: 'Branch Admin'
    }
  }),
  mounted() {
    this.user.branches = [{ organisationId: this.branchId }]
  },
  computed: {
    ...mapGetters({
      branch: 'auth/branch'
    }),
    branchId() {
      return this.branch.id
    }
  },
  methods: {
    async submit() {
      this.$wait.start('submit')
      const valid = await this.$validator.validate()

      if (valid) {
        try {
          await account.createUser(this.user)
          this.close()
          this.$root.$emit('fetch')
        } catch (err) {
          this.$wait.end('submit')
        }
      }
      this.$wait.end('submit')
    },
    close() {
      return this.$modal.close()
    }
  }
}
</script>
